import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(() => ({
    provide: {
        throwError: (error: any, msg: string) => {
            const status = error?.response?.status || 500
            const code = error?.code || ''
            const stack = error?.response?.data?.exception

            const newError = new Error(
                `Error ${status} ${code}: ${msg} stack : ${JSON.stringify(
                    stack
                )}`,
                error
            ) as any

            if (status !== 401 && status !== 403 && status !== 404) {
                Sentry.captureException(newError)
            }
        }
    }
}))
