import { default as indexMz8QuLoxg6Meta } from "/var/www/mysite/pages/index.vue?macro=true";
import { default as login9uF8u0bgOWMeta } from "/var/www/mysite/pages/login.vue?macro=true";
import { default as editOP2VeQfaWMMeta } from "/var/www/mysite/pages/partners/[idPartner]/edit.vue?macro=true";
import { default as indexb5TPSzoWcuMeta } from "/var/www/mysite/pages/partners/[idPartner]/index.vue?macro=true";
import { default as dataGM0ICIWbaIMeta } from "/var/www/mysite/pages/partners/[idPartner]/networks/[networkType]/[idNetwork]/data.vue?macro=true";
import { default as engagementsZ6syhZTunFMeta } from "/var/www/mysite/pages/partners/[idPartner]/networks/[networkType]/[idNetwork]/engagements.vue?macro=true";
import { default as indexxMdcDpwayHMeta } from "/var/www/mysite/pages/partners/[idPartner]/networks/[networkType]/[idNetwork]/inbox/index.vue?macro=true";
import { default as stationsQVyj9dqGk1Meta } from "/var/www/mysite/pages/partners/[idPartner]/networks/[networkType]/[idNetwork]/stations.vue?macro=true";
import { default as indexk3niGtI25cMeta } from "/var/www/mysite/pages/partners/[idPartner]/networks/index.vue?macro=true";
import { default as new99UGU6KTiQMeta } from "/var/www/mysite/pages/partners/[idPartner]/networks/new.vue?macro=true";
import { default as networksqlJtR6KwJ5Meta } from "/var/www/mysite/pages/partners/[idPartner]/networks.vue?macro=true";
import { default as parametersIrCYf4SsuzMeta } from "/var/www/mysite/pages/partners/[idPartner]/parameters.vue?macro=true";
import { default as index1HD4Srws9yMeta } from "/var/www/mysite/pages/partners/index.vue?macro=true";
import { default as newpvHVmRSKk5Meta } from "/var/www/mysite/pages/partners/new.vue?macro=true";
import { default as indexn4iGeP8frHMeta } from "/var/www/mysite/pages/password-reset/index.vue?macro=true";
import { default as update9O9ZuPx673Meta } from "/var/www/mysite/pages/password-reset/update.vue?macro=true";
import { default as index3F5UJ2g2kfMeta } from "/var/www/mysite/pages/profile/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/mysite/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login9uF8u0bgOWMeta || {},
    component: () => import("/var/www/mysite/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "partners-idPartner-edit",
    path: "/partners/:idPartner()/edit",
    component: () => import("/var/www/mysite/pages/partners/[idPartner]/edit.vue").then(m => m.default || m)
  },
  {
    name: "partners-idPartner",
    path: "/partners/:idPartner()",
    component: () => import("/var/www/mysite/pages/partners/[idPartner]/index.vue").then(m => m.default || m)
  },
  {
    name: networksqlJtR6KwJ5Meta?.name,
    path: "/partners/:idPartner()/networks",
    component: () => import("/var/www/mysite/pages/partners/[idPartner]/networks.vue").then(m => m.default || m),
    children: [
  {
    name: "partners-idPartner-networks-networkType-idNetwork-data",
    path: ":networkType()/:idNetwork()/data",
    component: () => import("/var/www/mysite/pages/partners/[idPartner]/networks/[networkType]/[idNetwork]/data.vue").then(m => m.default || m)
  },
  {
    name: "partners-idPartner-networks-networkType-idNetwork-engagements",
    path: ":networkType()/:idNetwork()/engagements",
    component: () => import("/var/www/mysite/pages/partners/[idPartner]/networks/[networkType]/[idNetwork]/engagements.vue").then(m => m.default || m)
  },
  {
    name: "partners-idPartner-networks-networkType-idNetwork-inbox",
    path: ":networkType()/:idNetwork()/inbox",
    component: () => import("/var/www/mysite/pages/partners/[idPartner]/networks/[networkType]/[idNetwork]/inbox/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-idPartner-networks-networkType-idNetwork-stations",
    path: ":networkType()/:idNetwork()/stations",
    component: () => import("/var/www/mysite/pages/partners/[idPartner]/networks/[networkType]/[idNetwork]/stations.vue").then(m => m.default || m)
  },
  {
    name: "partners-idPartner-networks",
    path: "",
    component: () => import("/var/www/mysite/pages/partners/[idPartner]/networks/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-idPartner-networks-new",
    path: "new",
    component: () => import("/var/www/mysite/pages/partners/[idPartner]/networks/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "partners-idPartner-parameters",
    path: "/partners/:idPartner()/parameters",
    component: () => import("/var/www/mysite/pages/partners/[idPartner]/parameters.vue").then(m => m.default || m)
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("/var/www/mysite/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-new",
    path: "/partners/new",
    component: () => import("/var/www/mysite/pages/partners/new.vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexn4iGeP8frHMeta || {},
    component: () => import("/var/www/mysite/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-update",
    path: "/password-reset/update",
    meta: update9O9ZuPx673Meta || {},
    component: () => import("/var/www/mysite/pages/password-reset/update.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/mysite/pages/profile/index.vue").then(m => m.default || m)
  }
]